<template>
  <div>
    <b-alert variant="danger" :show="clientData === undefined">
      <h4 class="alert-heading">Error fetching client data</h4>
      <div class="alert-body">
        No client found with this client id. Check
        <b-link class="alert-link" :to="{ name: 'clients-list' }"> Client List </b-link>
        for other clients.
      </div>
    </b-alert>
    <div v-if="clientData && Object.keys(clientData).length > 0">
      <b-card no-body class="p-1 px-2">
        <!-- Client Info: Top col -->
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <!-- <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap"> -->
          <span v-if="clientData.verified_at !== null">
            <feather-icon size="14" icon="CheckIcon" style="color: green;" />
            Email Verified {{ getCorrectDateTime(clientData.verified_at) }}
          </span>
          <!-- </h4> -->
          <div class="d-flex detail-top-b-list flex-wrap justify-content-end">
            <b-button class="px-3" :to="{ name: 'clients-edit', params: { id: clientData.id } }"
              variant="primary"><feather-icon class="mr-1" size="14" icon="Edit2Icon" />Edit</b-button>
            <b-button class="px-3" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
            <b-button class="px-3" variant="outline-danger" v-if="ability.can('delete', 'client_account')"
              @click="deleteClient(clientData.id)"><feather-icon class="mr-1" size="14"
                icon="Trash2Icon" />Delete</b-button>
          </div>
        </div>
        <hr />
        <!-- Client Table: Left col -->
        <b-row class="info-wrap px-1">
          <b-col title="name(en)" cols="12" sm="12" md="12">
            <template>
              <feather-icon v-if="clientData.is_active" size="14" icon="CircleIcon" style="color: green;" />
              <feather-icon v-else size="14" icon="CircleIcon" style="color: red;" />
              <h4 class="ml-1 mb-0 font-weight-bolder">{{ clientData.first_name }} {{ clientData.last_name }}
                ({{ clientData.id }})</h4>
            </template>
          </b-col>

          <b-col title="phone number" cols="12" sm="12" md="12">
            <feather-icon class="mr-1" size="14" icon="PhoneCallIcon" />
            {{ clientData.phone_number }}
          </b-col>

          <b-col title="gender" cols="12" sm="12" md="12">
            <feather-icon class="mr-1" size="14" icon="UsersIcon" />
            {{ clientData.gender.name }}
          </b-col>

          <b-col title="referred_customers" cols="12" sm="12" md="12">
            <feather-icon class="mr-1" size="14" icon="UsersIcon" />
            Current Referred Customers: {{ referredClientData }}
          </b-col>

          <!-- <b-col title="Date of Birth" cols="12" sm="12" md="12">
            <feather-icon class="mr-1" size="14" icon="CalendarIcon" />
            {{ clientData.date_of_birth }}
          </b-col> -->


          <hr class="w-100 d-md-none" />
          <!-- <b-col title="Address" cols="12" sm="8">
            <feather-icon class="mr-1" size="14" icon="MapPinIcon" />
            {{ clientData.address }}
          </b-col> -->
        </b-row>
      </b-card>

      <template>
        <b-tabs fill>
          <b-tab title="Orders" active>
            <b-card>
              <b-card-body>
                <h4>Orders</h4>
                {{ orderData }}
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab title="Favourite Products">
            <b-card>
              <b-card-body>
                <h4>Favourite Products</h4>
                {{ favoriteProductData }}
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab title="Discounts" active>
            <b-card>
              <b-card-body>
                <h4>Active Discounts</h4>
                <template v-if="activeDiscountData && activeDiscountData.length > 0">
                  <b-row>
                    <b-col cols="12" md="6" lg="4" v-for="discount in activeDiscountData" :key="discount.id">
                      <b-card class="discount-card">
                        <div class="d-flex align-items-center mb-1">
                          <div class="discount-badge" :class="getBadgeClass(discount.type)">
                            {{ getDiscountType(discount.type) }} - {{ discount.name }}
                            <span v-if="discount.code">
                              <br>
                              [CODE: {{ discount.code }}]
                            </span>
                          </div>
                        </div>

                        <div class="discount-details">
                          <div class="mb-1">
                            <h6>Discount Value</h6>
                            <p class="font-weight-bold">
                              {{ formatDiscountValue(discount.discount_type, discount.discount_value) }}
                              <span v-if="discount.minimum_spend !== 0 || discount.minimum_spend !== null">
                                (Minimum Spend: NPR{{ discount.minimum_spend }})
                              </span>
                            </p>
                          </div>

                          <div class="mb-1">
                            <h6>Expiry Date</h6>
                            <p class="font-weight-bold">
                              {{ discount.expiry_date }}
                            </p>
                            <div class="status-indicator mt-1" :class="discount.is_active ? 'active' : 'inactive'">
                              {{ discount.is_active ? 'Active' : 'Inactive' }}
                            </div>
                          </div>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>

                <h4>Inactive/Expired Discounts</h4>
                <template v-if="inactiveDiscountData && inactiveDiscountData.length > 0">
                  <b-row>
                    <b-col cols="12" md="6" lg="4" v-for="discount in inactiveDiscountData" :key="discount.id">
                      <b-card class="discount-card">
                        <div class="d-flex align-items-center mb-1">
                          <div :class="getBadgeClass(discount.type)">
                            {{ getDiscountType(discount.type) }} - {{ discount.name }}
                            <span v-if="discount.code">
                              <br>
                              [CODE: {{ discount.code }}]
                            </span>
                          </div>
                        </div>

                        <div class="discount-details">
                          <div class="mb-1">
                            <h6>Discount Value</h6>
                            <p class="font-weight-bold">
                              {{ formatDiscountValue(discount.discount_type, discount.discount_value) }}
                            </p>
                          </div>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>

              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab title="Address Book">
            <b-card>
              <b-card-body>
                <h4>Address Book</h4>
                {{ addressBookData }}
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab title="Referred Customers">
            <b-card>
              <b-card-body>
                <h4>Referred Customers</h4>
                {{ referredClientData }}
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </template>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  VBToggle,
  BTabs,
  BTab,
  BTable,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import clientStoreModule from "../clientStoreModule";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    draggable,
    vSelect,
    BTabs,
    BTab,
    BTable,
  },
  data() {
    return {
      ability,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    const clientData = ref({});
    const addressBookData = ref([]);
    const activeDiscountData = ref([]);
    const promotionalDiscountData = ref([]);
    const inactiveDiscountData = ref([]);
    const orderData = ref([]);
    const favoriteProductData = ref([]);
    const referredClientData = ref(0);

    const genders = ref([]);
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";

    const client = {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      gender_id: 1,
      phone_number: "",
      referred_code: null,
      is_active: 1,
    };

    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-client/fetchClientDetail", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          clientData.value = response.data.customer;
          addressBookData.value = response.data.address_books;
          activeDiscountData.value = response.data.customer_active_discount;
          inactiveDiscountData.value = response.data.customer_inactive_discount;
          orderData.value = response.data.orders;
          favoriteProductData.value = response.data.customer_favourite_products;
          referredClientData.value = response.data.referred_customers;
        })
        .catch((error) => {
          console.log("error when fetching client", error);
          if (error.response.status === 404) {
            clientData.value = undefined;
            addressBookData.value = [];
            activeDiscountData.value = [];
            inactiveDiscountData.value = [];
            orderData.value = [];
            favoriteProductData.value = [];
            referredClientData.value = 0;
          }
        });
    };

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { genders: true })
        .then((response) => {
          genders.value = response.data.genders;
        });
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    return {
      clientData,
      addressBookData,
      activeDiscountData,
      promotionalDiscountData,
      inactiveDiscountData,
      orderData,
      favoriteProductData,
      referredClientData,
      refetchData,
      genders,
    };
  },
  methods: {
    getBadgeClass(type) {
      return {
        'badge-referral': type === 'referral',
        'badge-promotional': type === 'promotional',
        'badge-individual': type === 'individual'
      }
    },
    getDiscountType(type) {
      return type === 'referral' ? 'Referral' :
        type === 'promotional' ? 'Promotional' : 'Individual'
    },
    formatDiscountValue(type, value) {
      return type === 'percentage' ? `${value}% (Percentage)` :
        type === 'fixed' ? `NPR${value} (Fixed)` : value
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.info-wrap {
  .col-12 {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
  }

  .tag-list {
    a {
      margin-right: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.drop-btn {
  margin-top: 5px;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  background-color: rgba(148, 200, 187, 0.2);

  svg {
    transition: all 300ms ease;
    top: 2px;
    right: 0px;
  }

  &.not-collapsed {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.drop-item {
  display: block;
  margin-top: 5px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid rgba(189, 189, 189, 0.5);
}

.discount-card {
  transition: transform 0.2s;
  border: 0.5px solid #e0e0e0;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

  &:hover {
    transform: translateY(-5px);
  }
}

.discount-badge {
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.9rem;

  &.badge-referral {
    background-color: #e8f5e9;
    color: #2e7d32;
  }

  &.badge-promotional {
    background-color: #fff3e0;
    color: #ef6c00;
  }

  &.badge-individual {
    background-color: #e3f2fd;
    color: #1976d2;
  }
}

.discount-details {
  h6 {
    color: #6e6b7b;
    font-size: 0.9rem;
  }

  p {
    color: #5e5873;
    margin-bottom: 0;
  }
}

.status-indicator {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;

  &.active {
    background-color: #e8f5e9;
    color: #2e7d32;
  }

  &.inactive {
    background-color: #fbe9e7;
    color: #d84315;
  }
}
</style>
