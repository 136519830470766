import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientsExportSimple(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/exportsimple", { params: data, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientsExport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/export", { params: data, responseType: "blob" })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/client/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClientDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/client/account_details?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClient(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/client", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSort(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("/client", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
